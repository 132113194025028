import { defer } from 'react-router-dom'

import request from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

import resetForm from '../Functions/resetForm'

import FormsStore from '../../Stores/Forms'

const FORM_NAME = 'Offer';

export default function OfferLoader ({ params })
{
	return defer({ offer: getOffer(true, params), params });
}

async function getOffer (retry=true, params)
{
	resetForm(FORM_NAME);

	try
	{
		var { data=[] } = await request('/offers/get')
		.post({ id: params.id || '-1' });

		var offer = data?.[0];

		if (!offer) throw { ERROR_CODE: 'NOT_FOUND', code: 404 };
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}
		else if (!offer)
		{
			throw { code: 404, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		offer = await getOffer(false, params);
	}

	FormsStore.update(function (store)
	{
		store[FORM_NAME] =
		{
			id: offer._id,

			'offer[title]': offer.title,
			'offer[text]': offer.text,
			'offer[base_price]': offer.base_price,
			'offer[discounted]': offer.discounted,
			'offer[vat]': offer.vat
		};
	});

	return offer;
}