import { useCallback, useEffect, useRef, useState } from 'react'

import CrossIcon from '../Icons/Cross'

import PanelRegistrationForm from './PanelComponents/PanelRegistrationForm'
import PanelLoginForm        from './PanelComponents/PanelLoginForm'
import PanelWelcome          from './PanelComponents/PanelWelcome'

import { useLocation } from 'react-router-dom'

import request from '../Functions/ajax/request'

import RolesStore from '../../Stores/Roles'

import UserPanelStore from '../../Stores/UserPanelStore'

import { closeUserPanel } from '../../StoresFunctions/userPanelFunctions'

import setRolesStore from '../../StoresFunctions/setRolesStore'
import setPagesStore from '../../StoresFunctions/setPagesStore'

const components =
{
	Registration: PanelRegistrationForm,
	Login: PanelLoginForm,
	Welcome: PanelWelcome
};

export default function UserPanel (props)
{
	const { pathname } = useLocation();

	const _ref_mounted = useRef();

	const prevent_redirect = UserPanelStore.useState(s => s.redirect === false);
	const close_on_login   = UserPanelStore.useState(s => s.close_on_login === true);
	const is__open         = UserPanelStore.useState(s => s.open === true);

	const my_roles = RolesStore.useState(s => s.roles || []);

	const [active_component, setActiveComponent] = useState('Login');

	const Component = components[active_component];

	useEffect(function ()
	{
		if (!is__open) return () => void 0;

		const controller = new AbortController();

		(async function ()
		{
			try
			{
				await request('/auth/get-my-account')
				.config({ keyword: 'action' })
				.post({ });
			}
			catch (update)
			{
				if (controller?.signal?.aborted) return;

				try
				{
					const { pages, version } = await request('/auth/get-pages')
					.config({ keyword: 'action' })
					.post({ });

					setPagesStore({ pages, version });
					setRolesStore({ });
				}
				catch (update)
				{
					if (controller?.signal?.aborted) return;
				}
			}
		})();

		return () => { controller?.abort?.() };
	},
	[is__open]);

	useEffect(function ()
	{
		if (!pathname) return () => void 0;

		if (!_ref_mounted.current)
		{
			_ref_mounted.current = true;

			return () => void 0;
		}

		closeUserPanel();
	},
	[pathname]);

	useEffect(function ()
	{
		if (my_roles.length)
		{
			setActiveComponent('Welcome');
		}
		else
		{
			setActiveComponent('Login');
		}
	},
	[my_roles]);

	const handleLogin = useCallback(function (response)
	{
		if (close_on_login)
		{
			closeUserPanel();
		}
	},
	[close_on_login]);

	return (
		<div className="c-user-panel">

			<div className="c-user-panel__panel">
				<button type="button" onClick={ closeUserPanel }
					className="c-user-panel__toggle-btn">
					<CrossIcon className="c-user-panel__toggle-icon-svg" />
					<span className="c-user-panel__toggle-label">
						Fermer
					</span>
				</button>

				<Component onLogin={ handleLogin }
					onRegister={ e => setActiveComponent('Registration') }
					no-redirect={ prevent_redirect }
				/>
			</div>

			<button className="c-user-panel__bg" type="button"
				onClick={ closeUserPanel }
			/>

		</div>
	);
}