import { defer } from 'react-router-dom'

import resetForm from '../Functions/resetForm.js'
import request   from '../Functions/ajax/request'

import FormsStore from '../../Stores/Forms'

import { someTime } from '../../../shared/utilities'

export default function NewsLoader ({ params })
{
	return defer({ data: getNews(true, params), params });
}

async function getNews (retry=true, params)
{
	resetForm('News');

	try
	{
		var { news } = await request('/news/get')
		.post({ id: params.id || '-1' });
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		news = await getNews(false, params);
	}

	FormsStore.update(function (store)
	{
		const form_state =
		{
			id: news._id,

			title: news.title || '',
			date: news.date || '',

			description: news.description || '',
			content: news.content || '',

			images: news.images || []
		};

		store['News'] = form_state;
	});

	return news;
}