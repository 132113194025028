import ReactDOM from 'react-dom'

import { memo, useState, useEffect, useCallback } from 'react'

import ModalContent from './ModalContent'

export default memo(function Modal ({ children, afterHidden, ...props })
{
	const [trg, setTrg] = useState();
	const [visible, setVisible] = useState(props.open === true);

	useEffect(function ()
	{
		if (props.open === true && !visible) setVisible(true);

		if (props.trg && props.trg !== trg)
		{
			setTrg(props.trg);
		}
	},
	[props.open, props.trg, visible, trg]);

	useEffect(function ()
	{
	// give back the focus to where it was before opening the modal
		if (!visible && trg && typeof trg.focus === 'function') trg.focus();

		if (!visible) setTrg(undefined);
	},
	[visible, trg]);

	const handleAnimationEnd = useCallback(function (e)
	{
		setVisible(props.open === true);

		if (typeof afterHidden === 'function' && props.open !== true)
		{
			afterHidden();
		}
	},
	[props.open, afterHidden]);

	const output = props.open || visible ?
	(
		<ModalContent onClose={ props.onClose }
			footer={ props.footer }
			onAnimationEnd={ handleAnimationEnd }
			open={ props.open === true }
			prevent-close={ props['prevent-close'] === true }
			close-classes={ props['close-classes'] }
			close-label={ props['close-label'] }
			title={ props.title }
			bemit={ props.bemit }
			scroll-area={ props['scroll-area'] }
			prevent-bg-close={ props['prevent-bg-close'] }>
			{ children }
		</ModalContent>
	)
	: undefined;

	return ReactDOM.createPortal(output, document.body);
});