import { useEffect, useId, useCallback, useRef, useState } from 'react'

import { cloneDeep, isEqual } from 'lodash'

import bemit from '../../Functions/bemit'

export default function ModalContent (props)
{
	const open_ref = useRef();
	const ref_history = useRef();

	const ref = useRef();
	const id = useId();

	const config_scroll = props['scroll-area'];

	const openModal = useCallback(function ()
	{
		const modal = document.getElementById(id);

		if (!modal) return;

		temperDocumentScroll('remove', config_scroll, id);

		modal.focus();

		modal.classList.remove('c-modal-cover--fade');
		modal.lastChild.classList.remove('c-modal--fade');
	},
	[config_scroll, id]);

	const closeModal = useCallback(function (e)
	{
		const modal = document.getElementById(id);

		if (!modal) return;

		if (props['prevent-close']) return;

		const other_modal = document
		.querySelector('[id="' + id + '"] ~ .js-app-modal-cover');

		if (other_modal) return;

		modal.classList.add('c-modal-cover--fade');
		modal.lastChild.classList.add('c-modal--fade');

		if (props.open === true) props.onClose(e);
	},
	[id, props]);

	useEffect(function ()
	{
		if (props.open === false)
		{
			closeModal();
		}
		else
		{
			openModal();
		}
	},
	[props.open, closeModal, openModal]);

	useEffect(function ()
	{
		const changed = open_ref.current !== props.open;

		open_ref.current = props.open;

		if (props.open !== true)
		{
			ref.current?.close?.();
		}
		else
		{
			ref_history.current = cloneDeep(window.history?.state);

			window.addEventListener('popstate', onHistoryPop);

			ref.current?.showModal?.();
		}

		if (changed)
		{
			if (props.open)
			{
				history.pushState('modal-' + id, '');
			}
			else if (window.history?.state === ('modal-' + id))
			{
				history.back(); // supprime l'historique qu'on a ajouté
			}
		}

		return () => window.removeEventListener('popstate', onHistoryPop);

		function onHistoryPop (e)
		{
			if (props.open !== true) return;

		// on est revenu à l'état précédant la modale ce qui veut dire que le
		// state de la modale a été retiré
			if (isEqual(window.history?.state, ref_history.current))
			{
				ref.current?.close?.();
			}
		}
	},
	[props.open, ref, id]);

	const stopPropagation = useCallback(e => e.stopPropagation(), []);

	const modal_controls = props.footer ? props.footer :
	(
		<button type="button" aria-label="Fermer"
			className={ props['close-classes'] || bemit('c-flat-btn') }
			onClick={ closeModal }>
			{ props['close-label'] || 'Fermer' }
		</button>
	);

	const no_bg_close = [true, 'true'].includes(props['prevent-bg-close']);

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<dialog id={ id } ref={ ref }
			className={ 'js-app-modal-cover ' + bemit('c-modal-cover', props.bemit) }
			onMouseDown={ no_bg_close ? undefined : closeModal }
			onTransitionEnd={ handleAnimationEnd }
			onAnimationEnd={ handleAnimationEnd }
			aria-modal="true" tabIndex="-1"
			aria-labelledby={ props['aria-label'] || props.title }
			onClose={ closeModal }>

			<div className={ bemit('c-modal', props.bemit) }
				onMouseDown={ stopPropagation }
				onTransitionEnd={ stopPropagation }
				onAnimationEnd={ stopPropagation }
				role="presentation">

				<div className="c-modal__header" id="modal-title">
					{
						props.title ?
						(
							<div className="c-modal__title">
								{ props.title }
							</div>
						)
						: undefined
					}
				</div>

				<div className="c-modal__body">
					{ props.children }
				</div>

				<div className="c-modal__controls-wrapper">
					<div className="c-modal__controls">
						{ modal_controls }
					</div>
				</div>

				<button type="button" className="c-modal__close"
					onClick={ closeModal }
					aria-labelledby={ 'modal-close' + id }>

					<span className="u-hide-visually" id={ 'modal-close' + id }>
						Fermer
					</span>

					<svg className="c-modal__close-icon" viewBox="0 0 40 40">
						<path d="M 10,10 L 30,30 M 30,10 L 10,30" />
					</svg>

				</button>

			</div>

		</dialog>
	);

	function handleAnimationEnd (e)
	{
		props.onAnimationEnd(e);

		if (props.open !== true)
		{
			temperDocumentScroll('restore', config_scroll, id);
		}
	}
}

function temperDocumentScroll (what, config_scroll, id)
{
	const native_scroller = document.scrollingElement || document.body;

	const scroll_width = window.innerWidth - native_scroller.clientWidth;

	let scroll_area = config_scroll
	? document.querySelector(config_scroll)
	: native_scroller;

	if (!scroll_area)
	{
		console.error('invalid modal scroll-area', config_scroll);
	}

	if (what === 'remove')
	{
		document.querySelector('html').classList.add('u-lock-scroll-' + id);

		if (scroll_width > 0)
		{
			scroll_area.style.paddingRight = scroll_width + 'px';
		}
	}
	else
	{
		document.querySelector('html').classList.remove('u-lock-scroll-' + id);

		const still_locked = document.querySelector('html')
		.className.includes('u-lock-scroll');

		if (!still_locked)
		{
			scroll_area.style.paddingRight = '';
		}
	}
}