import { Link } from 'react-router-dom'

export default function FooterLegal ()
{
	return (
		<div className="o-container c-footer__legal-content">

			<p className="c-footer__copyright">
				Copyright © 2024 OAA Online.
			</p>

			<ul className="c-footer__legal-links">

				<li className="c-footer__legal-item">
					<button type="button" onClick={ e => window.openCookiesBanner(e) }
						className="c-footer__legal-a">
						Gestion des cookies
					</button>
				</li>

				<li className="c-footer__legal-item">
					<Link className="c-footer__legal-a" to="/mentions-legales">
						Mentions légales
					</Link>
				</li>

			</ul>

		</div>
	);
}