import CookiesStore from '../../Stores/CookiesStore'

const code = 'gtag';

export default function loadGoogleTagManager (gtag_code)
{
	const exists = document.getElementById('front-gtag-manager');

	if (exists || !gtag_code) return;

	const script = document.createElement('script');

	script.src = 'https://www.googletagmanager.com/gtag/js?id='+ gtag_code.trim();
	script.sync = true;
	script.id  = 'front-gtag-manager';

	return new Promise(function (resolve, reject)
	{
		script.addEventListener('load', function ()
		{
			gtag('js', new Date());
			gtag('config', 'G-DWFRD06S47');

			CookiesStore.update(function (store)
			{
				store['loaded_' + code] = true;
			});
		});

		document.body.appendChild(script);
	});

	function gtag (...args)
	{
		(window.dataLayer || []).push(args);
	}
}