import { Store } from 'pullstate'

import { isTrue, isFalse } from '../../shared/utilities'

const enabled = ['gtag'].reduce(function (config, code)
{
	const storage_value = window.localStorage.getItem('custom_consent_' + code);

	config['enabled_' + code] = isTrue(storage_value)
	? true
	: isFalse(storage_value)
	? false
	: undefined;

	return config;
},
{ });

const CookiesStore = new Store(
{
	...enabled
});

export default CookiesStore;