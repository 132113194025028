import { Outlet, useLoaderData } from 'react-router-dom'

import SuspendedLayout from '../../SuspendedLayout'

export default function PrintLayout ()
{
	const { page } = useLoaderData();

	return (
		<SuspendedLayout resolve={ page }>

			<div className="pdf-page">
				<div className="pdf-page__content">
					<Outlet />
				</div>
			</div>

		</SuspendedLayout>
	);
}