import cookies_config from '../Components/Cookies/cookies_config'

import CookiesStore from '../Stores/CookiesStore'

export default function disableAllCookies ()
{
	const codes = Object.keys(cookies_config);

	CookiesStore.update(function (store)
	{
		codes.forEach(code => { store['enabled_' + code] = false; });
	});
}