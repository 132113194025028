import { BsSuitHeartFill } from 'react-icons/bs'

export default function FooterMadeBy ()
{
	return (
		<div className="o-container c-footer__made-by-content">

			<p className="c-footer__made-by">
				Réalisé avec
				{ ' ' }
				<BsSuitHeartFill className="c-footer__made-by-icon" />
				{ ' par ' }
				<a className="c-footer__made-by-a"
					href="https://www.panda-one.fr"
					target="_blank" rel="noreferrer">
					Panda One
				</a>
			</p>

		</div>
	);
}