import { defer } from 'react-router-dom'

import request   from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default function StripeSuccessLoader ({ params })
{
	return defer({ data: getOrder(true, params), params });
}

async function getOrder (retry=true, params)
{
	try
	{
		var { order } = await request('/orders/get-from-stripe-confirmation')
		.post({ token_str: window.localStorage.getItem('pay_token') });
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		order = await getOrder(false, params);
	}

	return order;
}