import { useCallback, useMemo } from 'react'

import CheckboxEl from '../Form/Elements/Managed/CheckboxEl'

import cookies_config from './cookies_config'

import bemit from '../Functions/bemit'

import { isTrue, isFalse } from '../../../shared/utilities'

import CookiesStore from '../../Stores/CookiesStore'

const NO_STATUS = { };

export default function CookieInfos ({ code, ...props })
{
	const config = cookies_config[code] || { };

	const id = 'cookie-config-toggle-' + code;

	const status = CookiesStore.useState(function (store)
	{
		return isTrue(store['enabled_' + code]);
	});

	const checked = isTrue(status);

	const is__disabled = isTrue(props.disabled) || isTrue(config.disabled);

	const toggle = useCallback(function (e)
	{
		CookiesStore.update(function (store)
		{
			store['enabled_' + code] = !isTrue(store['enabled_' + code]);
		});

		window.localStorage.setItem('cookies_consent', 'custom');
	},
	[code]);

	const is__status_false = isFalse(status);

	const modifiers = useMemo(function ()
	{
		return [code]
		.concat(checked ? ['checked'] : is__status_false ? ['disabled'] : []);
	},
	[code, checked, is__status_false]);

	if (!cookies_config[code]) return null;

	return (
		<div className={ bemit('c-cookie-parameter', modifiers) }
			data-cookie-config-code={ code }>

			<div className="c-cookie-parameter__title">
				{ config.title }
			</div>

			<div className="c-cookie-parameter__value">
				<CheckboxEl name={ 'cookie-switch-' + code }
					onChange={ is__disabled ? undefined : toggle }
					checked={ checked }
					bemit={ ['switch'] }
					status={ NO_STATUS }
					disabled={ is__disabled }
					id={ id }
					className="c-cookie-parameter__switch"
				/>

				<label htmlFor={ id } className="c-cookie-parameter__status">
					{ checked ? 'Actif' : 'Inactif' }
				</label>
			</div>

			<div className="c-cookie-parameter__description"
				dangerouslySetInnerHTML={ { __html: config.description || '' } }
			/>

		</div>
	);
}