import { defer } from 'react-router-dom'

import request from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

import resetForm from '../Functions/resetForm'

const FORM_NAME = 'Offer';

export default function OrderLoader ({ params })
{
	return defer({ data: getOrder(true, params), params });
}

async function getOrder (retry=true, params)
{
	resetForm(FORM_NAME);

	try
	{
		var { order } = await request('/orders/details/' + params.id)
		.config({ keyword: 'commande' })
		.post({ });

		if (!order) throw { ERROR_CODE: 'NOT_FOUND', code: 404 };
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}
		else if (!order)
		{
			throw { code: 404, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		order = await getOrder(false, params);
	}

	return order;
}