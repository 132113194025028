import { useCallback, useEffect, useRef, useState } from 'react'

import { IoMdSwitch } from 'react-icons/io'

import CookieParameter from './CookieParameter'

import CookiesDetailsModal from '../Modals/CookiesDetailsMotal'

import bemit from '../Functions/bemit'

import CookiesStore from '../../Stores/CookiesStore'

import disableAllCookies from '../../StoresFunctions/disableAllCookies'
import enableAllCookies  from '../../StoresFunctions/enableAllCookies'

const is__prerendered = document.querySelector('meta[rel="x-prerender-render-id"]')
? true
: false;

export default function CookiesAlert ()
{
	const ref = useRef();

	const [hidden, setHidden] = useState(
		is__prerendered ? true
		: window.localStorage.getItem('cookies_consent') ? true : false
	);

	useEffect(function ()
	{
		if (is__prerendered)
		{
			return window.localStorage.setItem('cookies_consent', 'true');
		}

		ref.current = { };

		ref.current.openCookiesBanner = function ()
		{
			setHidden(false);
		};

		ref.current.accept = function ()
		{
			window.localStorage.setItem('cookies_consent', 'true');
			enableAllCookies();
			setHidden(true);
		};

		ref.current.refuse = function ()
		{
			window.localStorage.setItem('cookies_consent', 'false');
			disableAllCookies();
			setHidden(true);
		};
	},
	[ref]);

	useEffect(function ()
	{
		if (is__prerendered) return;

		window.openCookiesBanner = function (e)
		{
			ref.current?.openCookiesBanner?.();
		};

		const user_consent = window.localStorage.getItem('cookies_consent');

		if (user_consent === 'false')
		{
			return ref.current?.refuse?.();
		}
		else if (user_consent === 'true')
		{
			return ref.current?.accept?.();
		}
	},
	[]);

	const accept = useCallback(function (e)
	{
		ref.current.accept?.();
	},
	[]);

	const refuse = useCallback(function (e)
	{
		ref.current.refuse?.();
	},
	[]);

	const hideAlert = useCallback(function (e)
	{
		setHidden(true);
	},
	[]);

	if (is__prerendered) return null;

	let output = hidden ? null :
	(
		<div className="o-cookies-alert">

			<p className="o-cookies-alert__text">
				Nous utilisons des cookies à des fins de mesure d'audience.
			</p>

			<button type="button" className={ bemit('c-button',
				['green', 'cookies-footer']) }
				onClick={ accept }>
				Accepter
			</button>

			<button type="button" className={ bemit('c-button',
				['red', 'cookies-footer']) }
				onClick={ refuse }>
				Refuser
			</button>

			<CookiesAlertCustomBtn />

		</div>
	);

	return (
		<>
			{ output }

			<CookieParameter code="gtag" />

			<CookiesDetailsModal onClose={ hideAlert } />
		</>
	);
}

// eslint-disable-next-line react/no-multi-comp
function CookiesAlertCustomBtn ({ lang })
{
	const openDetails = useCallback(function (e)
	{
		e.persist();

		CookiesStore.update(function (store)
		{
			store.open = true;
			store.trg = e.target;
		});
	},
	[]);

	return (
		<button type="button" className={ bemit('c-button',
			['white', 'cookies-footer']) }
			onClick={ openDetails }>
			<IoMdSwitch className="c-button__icon" />
			Affiner mes choix
		</button>
	);
}

function isDoNotTrackEnabled ()
{
	const enabled =
	[
		window.navigator.doNotTrack === '1',
		window.navigator.doNotTrack === 'yes',
		window.doNotTrack === '1',
		window.doNotTrack === 'yes',
		window.navigator.globalPrivacyControl
	]
	.some(condition => condition);

	return enabled;
}