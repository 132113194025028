import FooterAddress from './FooterAddress'

import { CGV_URL } from '../../shared_config'

export default function FooterContent ()
{
	return (

		<div className="o-container c-footer__main-content">

			<div className="c-footer__col">
				<h2 className="c-footer__title">
					Qui sommes-nous
				</h2>

				<p className="c-footer__description">
					Première Formation Française pour les professionnels et référente dans ce domaine, l'OAA a mis toute son expertise dans la réalisation de cette formation en ligne, avec ou sans accompagnement.
				</p>
			</div>

			<div className="c-footer__col">
				<h2 className="c-footer__title">
					Contactez-nous
				</h2>

				<FooterAddress />
			</div>

			<div className="c-footer__col">

				<h2 className="c-footer__title">
					Liens utiles
				</h2>

				<ul className="c-footer__page-links">

					<li className="c-footer__page-links-item">
						<a className="c-footer__page-links-a"
							href="/formations-theoriques">
							Formation théorique
						</a>
					</li>

					<li className="c-footer__page-links-item">
						<a className="c-footer__page-links-a"
							href="/formations-pratiques">
							Formation pratique
						</a>
					</li>

					<li className="c-footer__page-links-item">
						<a className="c-footer__page-links-a"
							href="/accompagnement-en-ligne">
							Accompagnement en ligne
						</a>
					</li>

					<li className="c-footer__page-links-item">
						<a className="c-footer__page-links-a"
							href="/actualites">
							Actualités
						</a>
					</li>

					<li className="c-footer__page-links-item">
						<a className="c-footer__page-links-a"
							href={ CGV_URL }
							target="_blank" rel="noreferrer">
							CGU &amp; CGV
						</a>
					</li>

				</ul>

			</div>

		</div>
	);
}