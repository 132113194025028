import React, { useCallback, useEffect, useRef } from 'react'

import Modal from './ModalTools/Modal'

import CookieInfos from '../Cookies/CookieInfos'

import bemit from '../Functions/bemit'

import CookiesStore from '../../Stores/CookiesStore'

export default function CookiesDetailsModal ({ onClose, ...props })
{
	const ref_close = useRef();
	const ref_open = useRef();

	const is__open = CookiesStore.useState(s => s.open === true);

	useEffect(function ()
	{
		ref_open.current = false;
		ref_close.current = onClose;
	},
	[onClose]);

	useEffect(function ()
	{
		if (!is__open && !ref_open.current) return () => void 0;

		ref_open.current = is__open;

		if (!is__open) ref_close.current?.();
	},
	[is__open]);

	const closeModal = useCallback(function (e)
	{
		CookiesStore.update(function (store)
		{
			store.open = false;
		});
	},
	[]);

	const footer =
	(
		<button type="button" aria-label="Fermer"
			className={ bemit('c-button') }
			onClick={ closeModal }>
			Fermer
		</button>
	);

	return (
		<Modal open={ is__open } onClose={ closeModal }
			bemit={ ['cookies-details', 'simple'] }
			trg={ props.trg }
			title="Paramètres de confidentialité"
			footer={ footer }>
			<CookieInfos code="gtag" />
		</Modal>
	);
}