import loadGoogleTagManager from './loadGoogleTagManager'

import { isTrue } from '../../../shared/utilities'

export default
{
	gtag:
	{
		title: 'Google Tag Manager',

		description: 'Nous recueillons des données anonymes par défaut.<br />En activant cette fonctionnalité vous assurez à l\'OAA Online un suivi plus fiable et de meilleure qualité lorsque vous naviguez sur la plateforme.',

		load: loadGoogleTagManager,

		init: function ()
		{
			window.dataLayer = [];

			const keyword = isTrue(window.localStorage.getItem('custom_consent_gtag'))
			? 'granted'
			: 'denied';

			window.dataLayer.push(
			[
				'consent',

				'default',
				{
					'ad_user_data': keyword,
					'ad_personalization': keyword,
					'ad_storage': keyword,
					'analytics_storage': keyword,

					'wait_for_update': 500
				}
			]);

			window.dataLayer.push(
			[
				'js',

				new Date()
			]);
		},

		onAcceptation: function ()
		{
			window.dataLayer.push(
			[
				'consent',

				'update',
				{
					'ad_user_data': 'granted',
					'ad_personalization': 'granted',
					'ad_storage': 'granted',
					'analytics_storage': 'granted'
				}
			]);
		},

		onDenied: function ()
		{
			window.dataLayer.push(
			[
				'consent',

				'update',
				{
					'ad_user_data': 'denied',
					'ad_personalization': 'denied',
					'ad_storage': 'denied',
					'analytics_storage': 'denied'
				}
			]);
		}
	}
}