import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Link } from 'react-router-dom'

import { TbUserScreen } from 'react-icons/tb'
import { HiMenuAlt2 }   from 'react-icons/hi'

import CrossIcon from '../Icons/Cross'

import Logo from '../Pure/Logo'

import bemit from '../Functions/bemit'

import nodeIsOrBelongsTo from '../Functions/nodeIsOrBelongsTo.js'

import FrontNavbar from './FrontNavbar'
import UserPanel   from './UserPanel'

import UserPanelStore from '../../Stores/UserPanelStore'

import { closeUserPanel, openUserPanel } from '../../StoresFunctions/userPanelFunctions'

export default function FrontHeader ()
{
	const [nav_open, setNavOpen] = useState(false);
	const [sticky, setSticky]    = useState(false);

	const user_panel = UserPanelStore.useState(s => s.open === true);

	const ref = useRef();
	const ref_ticking = useRef();

	const toggleNavBar = useCallback(function (e)
	{
		setNavOpen(open => !open);
	},
	[]);

	const modifiers = useMemo(function ()
	{
		const modifiers = nav_open ? ['open'] : [];

		if (sticky) modifiers.push('sticky');

		return modifiers;
	},
	[nav_open, sticky]);

	useEffect(() =>
	{
		const listener = document.addEventListener('click', function (e)
		{
		// ignore le click s'il est dans le header
			if (nodeIsOrBelongsTo(e?.target, ref.current)) return;

			setNavOpen(false);
		});

		return () => window.removeEventListener('click', listener);
	},
	[ref]);

	useEffect(() =>
	{
		const listener = window.addEventListener('scroll', function (e)
		{
			if (ref_ticking.current === true) return;

			ref_ticking.current = true;

			window.requestAnimationFrame(function ()
			{
				ref_ticking.current = false;

				const height = ref.current?.getBoundingClientRect()?.height;

				setSticky(document.scrollingElement?.scrollTop > height);
			});
		});

		return () => { window.removeEventListener('scroll', listener); ref_ticking.current = false; }
	},
	[ref]);

	const MenuIcon = nav_open
	? CrossIcon
	: HiMenuAlt2;

	return (
		<>
			<header className={ bemit('c-header', modifiers) + ' js-front-header' }
				ref={ ref }>

				<div className="o-container c-header__container">

					<div className="c-header__logo">
						<Link to="/" className="c-header__logo-link">
							<Logo className="c-header__logo-img" />
						</Link>
					</div>

					<FrontNavbar MenuIcon={ MenuIcon }
						onToggle={ toggleNavBar }
						open={ nav_open }
					/>

					<button type="button" className="c-header__user-toggle"
						onClick={ openUserPanel }>
						<TbUserScreen className="c-header__user-toggle-icon" />
						<span className="u-readers-only">
							Menu utilisateur
						</span>
					</button>

				</div>

			</header>

			{
				user_panel ?
				(
					<UserPanel />
				)
				: undefined
			}

		</>
	);
}