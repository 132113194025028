import { useMemo, useRef, useId, useCallback } from 'react'

import { HiOutlineExclamation } from 'react-icons/hi'

import { Ripple } from 'primereact/ripple'

import bemit from '../../../Functions/bemit'

import { isTrue } from '../../../../../shared/utilities'

export default function CheckboxEl ({ onChange, ...props })
{
	const id = useId();
	const ref = useRef();

	const is__disabled = isTrue(props['read-only']);

	const modifiers = useMemo(function ()
	{
		const modifiers = [].concat(props.bemit || []);

		if (props.checked) modifiers.push('checked');
		if (is__disabled) modifiers.push('disabled');
		if (props.error && props.show_error) modifiers.push('error');
		if (props.required) modifiers.push('required');

		return modifiers;
	},
	[props.checked, is__disabled, props.bemit, props.error, props.show_error, props.required]);

	const handleClick = useCallback(function (e)
	{
	// permet d'éviter de focus l'input lorsqu'il s'agit d'un clic souris
	// et pas espace/enter (il n'y a pas de façon de savoir si c'est un click souris)
	// et mousedown/up sont exclus (la checkbox se coche après)
		if (e.target !== ref.current)
		{
			e.preventDefault();

			ref.current?.blur?.();

			onChange();
		}
	},
	[onChange]);

	const html_id = props.id || id;

	return (
		<label className={ bemit('c-checkbox', modifiers) }
			htmlFor={ html_id }
			onClick={ is__disabled ? undefined : handleClick }>

			<input type="checkbox" checked={ props.checked } id={ html_id }
				onChange={ onChange }
				ref={ ref }
				disabled={ is__disabled }
			/>

			<div className="c-checkbox__indicator" />

			<span className={
					bemit('c-form-element-label', modifiers.concat(['checkbox']))
				}>
				{ props.label }
			</span>

			<Ripple />

			{
				props.show_error && props.error ?
				(
					<span className={ bemit('c-field-error', ['checkbox']) }>
						<HiOutlineExclamation className="c-field-error__icon" />
						{ props.error }
					</span>
				)
				: undefined
			}
		</label>
	);
}