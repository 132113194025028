export const site_name = 'OAA Online';

export const logo = '/images/logo/logo-oaa.svg';

export const white_logo = '/images/logo/logo-oaa-blanc.svg';

export const colors =
{
	primary: '#FD780F'
};

export const js_version = '1.1.7';

export const js_refresh_rate = { minutes: 30 }; // luxon Duration params

export const static_CDN = 'https://cdn-oaa-online.s3.gra.io.cloud.ovh.net';

export const videos_count = 1017;
export const PDFs_count = 320;

export const allowed_payments_methods =
[
	{
		value: 'stripe',
		label: 'Stripe',
		by_label: 'via Stripe'
	},
	{
		value: 'transfer',
		label: 'Virement',
		by_label: 'par virement'
	}
];

export const videos_guard_text =
(
	'Réservé aux détenteurs d\'une des offres Théorique.'
);

export const TIMEZONE = { zone: 'Europe/Paris' }; // luxon timezone

export const VATs =
[
	{ value: '0', label: '0' },
	{ value: '20', label: '20%' }
];

export const CGV_URL = '/documents/CGV-CGU_OAA_Online.pdf';

export const bill_config =
{
	name: 'SAS BCAS',

	address:
	{
		line_1: '1 rue Raoul Follereau',
		line_2: 'Bât Gascogne, Terrasses St Charles',
		zip_code: '64200',
		city: 'Biarritz',
		country: 'FR'
	}
};

export const google_tag = 'G-DWFRD06S47';

export const NEWS_PAGINATION = 30;

const config = { site_name, logo, colors, js_version, js_refresh_rate, white_logo, static_CDN, videos_count, PDFs_count, allowed_payments_methods, VATs, CGV_URL,
bill_config, NEWS_PAGINATION };

export default config;