import { useCallback, useState } from 'react'

import { Link, useLocation } from 'react-router-dom'

import Spinner from '../../Spinners/Spinner'

import request from '../../Functions/ajax/request'
import bemit   from '../../Functions/bemit'

import fetchAccessConfig from '../../../StoresFunctions/fetchAccessConfig'
import setPagesStore     from '../../../StoresFunctions/setPagesStore'
import setRolesStore     from '../../../StoresFunctions/setRolesStore'

import { isTrue } from '../../../../shared/utilities'

import ContentStore from '../../../Stores/ContentStore'

import RolesStore from '../../../Stores/Roles'

export default function PanelWelcome (props)
{
	const { pathname } = useLocation();

	const [disconnecting, setDisconecting] = useState(false);

	const access_videos = ContentStore.useState(s => isTrue(s.videos));
	const access_pdf    = ContentStore.useState(s => isTrue(s.pdf));

	const my_roles = RolesStore.useState(s => s.roles || []);

	const my_home = RolesStore.useState(s => s.home || '/');

	const logout = useCallback(function (e)
	{
		const controller = new AbortController();

		setDisconecting(true);

		(async function logout ()
		{
			try
			{
				var { pages, version } = await request('/auth/logout')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				if (controller?.signal?.aborted) return;
				return;
			}

			setPagesStore({ pages, version });
			setRolesStore({ });
			fetchAccessConfig({ });

			setDisconecting(false);
		})();

		return () => controller?.abort?.();
	},
	[]);

	return (
		<div className="c-panel-welcome">
			<div className="c-panel-welcome__title">
				Bienvenue sur OAA Online
			</div>

			{
				access_videos || access_pdf ?
				(
					<div className="c-panel-welcome__text">
						Vous pouvez maintenant accéder aux formations
						{
							access_pdf && !access_videos
							? ' théoriques.'
							: access_videos && !access_pdf
							? ' pratiques.'
							: '.'
						}
					</div>
				)
				: undefined
			}

			{
				my_roles.includes('admin') || my_roles.includes('oaa') || my_roles.includes('editor') ?
				(
					<Link to={ my_home }
						className="c-button c-button--user-panel">
						Espace d'administration
					</Link>
				)
				: undefined
			}

			{
				pathname !== '/mon-compte' && !my_roles.includes('student') ?
				(
					<Link to="/mon-compte"
						className="c-button c-button--user-panel">
						Mon compte
					</Link>
				)
				: undefined
			}

			{
				my_roles.includes('customer') && pathname !== '/mes-commandes' ?
				(
					<Link to="/mes-commandes"
						className="c-button c-button--user-panel">
						Mes commandes
					</Link>
				)
				: undefined
			}

			{
				my_roles.includes('customer') || my_roles.includes('student')
				? undefined :
				(
					<div className="c-panel-welcome__logout">
						<button type="button"
							className={ bemit('c-button', ['ghost-white']) }
							onClick={ logout }>
							Déconnexion
							<Spinner active={ disconnecting }
								bemit={ ['bg-oaa', 'primary'] }
							/>
						</button>
					</div>
				)
			}

		</div>
	);
}