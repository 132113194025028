import FooterContent from './FooterContent'
import FooterMadeBy  from './FooterMadeBy'
import FooterLegal   from './FooterLegal'
import FooterTop     from './FooterTop'

export default function Footer ()
{
	return (
		<footer className="c-footer">

			<div className="c-footer__top">
				<FooterTop />
			</div>

			<div className="c-footer__main">
				<FooterContent />
			</div>

			<div className="c-footer__legal">
				<FooterLegal />
			</div>

			<div className="c-footer__made-by">
				<FooterMadeBy />
			</div>

		</footer>
	);
}