import { defer } from 'react-router-dom'

import request from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default function BillLoader ({ params })
{
	return defer({ data: getData(true, params), params });
}

async function getData (retry=true, params)
{
	try
	{
		var { bill } = await request('/bills/get')
		.config({ })
		.post({ number: params.number });
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE === 'NOT_FOUND')
		{
			throw { code: 404, throw_up: true, message: update.error };
		}

		if (!retry) throw { throw_up: true, ...update };

		await someTime(5000);

		return await getData(false, params);
	}

	return bill;
}