import { defer } from 'react-router-dom'

import request   from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default function FrontNewsLoader ({ params })
{
	return defer({ page: getNews(true, params), params });
}

async function getNews (retry=true, params)
{
	try
	{
		var { news } = await request('/front/news/' + params.slug || '-1')
		.config({ })
		.get({ });
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		news = await getNews(false, params);
	}

	return { news };
}