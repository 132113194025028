import { Link } from 'react-router-dom'

import { memo } from 'react'

import Message from '../Pure/Message'

import RolesStore from '../../Stores/Roles'

const form_status =
[
	{ field: 'info', color: 'green', icon: 'checked' },
	{ field: 'warning', color: 'orange', icon: 'info' },
	{ field: 'error', color: 'red', icon: 'info' },
	{ field: 'statement', color: 'blue', icon: 'info' }
];

export default memo(function FormStatus (props)
{
	const my_roles = RolesStore.useState(s => s.roles || []);

	const status = form_status
	.filter(status_data => typeof props[status_data.field] !== 'undefined')
	.map(function (status_data, index)
	{
		return (
			<Message color={ status_data.color } icon={ status_data.icon }
				style={ props.style } key={ status_data.field }
				Icon={ props.Icon }>
				{ props[status_data.field] }
				{ props.children }
				{
					['FORBIDDEN', 'DENIED'].includes(props.ERROR_CODE) &&
						!props['hide-auto-msg'] && my_roles.length &&
						!my_roles.includes('customer') ?
					(
						<>
							&nbsp;
							<Link to="/login">
								Page de connexion
							</Link>
						</>
					)
					: undefined
				}
			</Message>
		);
	});

	return status.length > 0
	? status
	: null;
});