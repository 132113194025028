import { defer } from 'react-router-dom'

import request from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default function OrderByTokenLoader ({ params })
{
	return defer({ data: getData(true, params), params });
}

async function getData (retry=true, params)
{
	try
	{
		var { order } = await request('/orders/get-by-token')
		.config({ })
		.post({ token_str: params.token });
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		return await getData(false, params);
	}

	order.__token_str = params.token;

	return order;
}