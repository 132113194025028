import { MdOutlinePhoneEnabled } from 'react-icons/md'
import { TbMapPinCheck } from 'react-icons/tb'
import { HiOutlineMail } from 'react-icons/hi'

export default function FooterAddress ()
{
	return (
		<address className="c-footer__address">

			<div className="c-footer__address-item">
				<div className="c-footer__addr-icon">
					<MdOutlinePhoneEnabled className="c-footer__addr-svg" />
				</div>

				<p className="c-footer__addr-text">
					<a href="tel:+33559235923" className="c-footer__addr-a">
						+33 5 59 23 59 23
					</a>
				</p>
			</div>

			<div className="c-footer__address-item">
				<div className="c-footer__addr-icon">
					<HiOutlineMail className="c-footer__addr-svg" />
				</div>

				<p className="c-footer__addr-text">
					<a href="mailto:contact@oaa-online.com"
						className="c-footer__addr-a">
						contact@oaa-online.com
					</a>
				</p>
			</div>

			<div className="c-footer__address-item">
				<div className="c-footer__addr-icon">
					<TbMapPinCheck className="c-footer__addr-svg" />
				</div>

				<p className="c-footer__addr-text">
					La Bergerie Nationale, Parc du Château
					<br />
					CS&nbsp;40609, 78514 Rambouillet
				</p>
			</div>

		</address>
	);
}