import { useEffect } from 'react'

import cookies_config from './cookies_config'

import CookiesStore from '../../Stores/CookiesStore'

import { isTrue } from '../../../shared/utilities'

export default function CookieParameter ({ code, ...props })
{
	const config = cookies_config[code] || { };

	const { onAcceptation, onDenied, init } = config;

	const load = typeof config.load === 'function' ? config.load : undefined;

	const active = CookiesStore.useState(function (store)
	{
		return isTrue(store['enabled_' + code]);
	});

	const param_id = CookiesStore.useState(function (store)
	{
		return store['param_' + code + '_id'];
	});

	useEffect(function ()
	{
		if (typeof init === 'function') init();
	},
	[init]);

	useEffect(function ()
	{
		if (typeof onDenied === 'function' && active === false)
		{
			onDenied();

			window.localStorage.setItem('custom_consent_' + code, 'false');
		}
		else if (typeof init === 'function' && active === true)
		{
			onAcceptation();

			window.localStorage.setItem('custom_consent_' + code, 'true');
		}

	},
	[code, onAcceptation, onDenied, active, init]);

	useEffect(function ()
	{
		if (!load || !param_id) return;

		load(param_id);
	},
	[param_id, load]);

	return null;
}