import { useEffect } from 'react'

import { Link, Outlet, useLoaderData } from 'react-router-dom'

import { FaArrowLeftLong } from 'react-icons/fa6'

import SuspendedLayout from '../../SuspendedLayout'

import Image from '../Pure/Image'
import Logo  from '../Pure/Logo'

import request from '../Functions/ajax/request'
import bemit   from '../Functions/bemit'

import RolesStore from '../../Stores/Roles'

import setRolesStore from '../../StoresFunctions/setRolesStore'

import config from '../../shared_config'

const img_config =
{
	original: 1000,
	desktop: 1000,

	available: [500, 1000, 1500, 2000, 3000]
};

export default function AuthLayout ()
{
	const { page } = useLoaderData();

	const roles_date = RolesStore.useState(s => s.date);

	useEffect(() =>
	{
		const controller = new AbortController();

		(async function fetchRoles ()
		{
			if (roles_date) return;

			try
			{
				var { roles, user_id } = await request('/auth/get-roles')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				return;
			}

			setRolesStore({ roles, user_id });
		})();

		return () => controller?.abort?.();
	},
	[roles_date]);

	return (
		<SuspendedLayout resolve={ page }>
			<div className="auth-page">

				<div className="auth-page__left">
					<h1 className="u-readers-only">
						{ config.site_name }
					</h1>

					<div className="auth-page__top-return">
						<Link to="/" className={ bemit('c-flat-btn', ['invisible']) }>
							<FaArrowLeftLong className="c-button__icon" />
							Retour au site
						</Link>
					</div>

					<div className="u-flex-grow-1" />

					<Link className="auth-page__logo-link" to="/login">
						<Logo className="auth-page__logo" width="243" height="184" />
					</Link>

					<Outlet />

					<div className="u-flex-grow-1" />
				</div>

				<div className="auth-page__right">
					<Image alt="" className="auth-page__right-img"
						src="/images/auth_bg.webp"
						src_config={ img_config }
					/>
				</div>

			</div>
		</SuspendedLayout>
	);
}