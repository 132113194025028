import { defer } from 'react-router-dom'

import resetForm from '../Functions/resetForm.js'
import request   from '../Functions/ajax/request'

import FormsStore from '../../Stores/Forms'

import { isTrue, someTime } from '../../../shared/utilities'

export default function CourseLoader ({ params })
{
	return defer({ area: getCourse(true, params), params });
}

async function getCourse (retry=true, params)
{
	resetForm('Course');

	try
	{
		var { data=[] } = await request('/courses/get')
		.post({ id: params.id || '-1' });

		var course = data?.[0];
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		course = await getCourse(false, params);
	}

	FormsStore.update(function (store)
	{
		const form_state =
		{
			'id': course._id,

			'course[title]': course.title || '',

			'course[discipline]': course.discipline || '',
			'course[linked_course]': course.linked_course || '',
			'course[area]': course.area || '',
			'course[method]': course.method || '',
			'course[summary]': course.summary || '',
			'course[table_of_contents]': course.table_of_contents || '',
			'course[files]': course.files || [],
			'course[type]': course.type,
			'course[free]': isTrue(course.free)
		};

		if (Array.isArray(course.species)) form_state.species = course.species;

		store['Course'] = form_state;
	});

	return course;
}