import { Link } from 'react-router-dom';

export default function FrontNavbar ({ open, ...props })
{
	const MenuIcon = props.MenuIcon;

	return (
		<nav className="c-header__navbar">

			<button type="button" className="c-header__toggle"
				onClick={ props.onToggle }>
				<MenuIcon className="c-header__toggle-icon" />
				<span className="u-readers-only">
					{
						open
						? 'Fermer le menu'
						: 'Ouvrir le menu'
					}
				</span>
			</button>

			<ul className="c-header__navbar-content"
				aria-hidden={ open ? 'false' : 'true' }>

				<li className="c-nav-item">
					<Link className="c-nav-item__link" to="/">
						Accueil
					</Link>
				</li>

				<li className="c-nav-item">
					<Link className="c-nav-item__link" to="/qui-sommes-nous">
						Qui sommes-nous
					</Link>
				</li>

				<li className="c-nav-item">
					<Link className="c-nav-item__link"
						to="/formations-theoriques">
						Formation théorique
					</Link>
				</li>

				<li className="c-nav-item">
					<Link className="c-nav-item__link"
						to="/formations-pratiques">
						Formation pratique
					</Link>
				</li>

				<li className="c-nav-item">
					<Link className="c-nav-item__link"
						to="/offre-OAA">
						Nos offres
					</Link>
				</li>

				<li className="c-nav-item">
					<Link className="c-nav-item__link"
						to="/contact">
						Contact
					</Link>
				</li>

			</ul>

		</nav>
	);
}